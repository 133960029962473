@import '@aurora/shared-client/styles/_variables.pcss';
@import '@aurora/shared-client/styles/mixins/_focus.pcss';

.lia-tag-wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  position: relative;
}

.lia-tag {
  display: inline-flex;
  align-items: center;
  padding: 5px;
  background-color: var(--lia-tag-bg-color);
  border-radius: var(--lia-tag-border-radius);
  user-select: none;

  &.lia-tag-add {
    column-gap: 3px;
  }

  &:hover {
    background-color: var(--lia-tag-bg-hover-color);
    outline: none;
  }
  @include default-focus-outline();
}

.lia-tag-preview {
  cursor: default !important;

  &:hover,
  &:focus {
    background-color: var(--lia-tag-bg-color);
  }
}

.lia-tag-text {
  display: block;
  text-transform: var(--lia-tag-text-transform);
  font-size: var(--lia-tag-font-size);
  font-weight: var(--lia-tag-font-weight);
  color: var(--lia-tag-color);
  line-height: 1.09090909;
  letter-spacing: var(--lia-tag-letter-spacing);
  white-space: normal;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  text-align: left;

  &.lia-tag-add-text {
    text-transform: var(--lia-tag-text-transform);
  }
}

.lia-tag-pager.lia-tag-pager {
  border: 0;
  padding: 5px;
  line-height: 1;

  .lia-tag-pager-icon {
    margin: -3px 0;
  }
}

.lia-tag-add {
  color: var(--lia-tag-color);

  .lia-tag-icon {
    fill: var(--lia-tag-color);
  }

  &:hover,
  &:focus {
    color: var(--lia-tag-hover-color);

    .lia-tag-icon {
      fill: var(--lia-tag-hover-color);
    }
  }
}

.lia-tag-box {
  padding: 0;
  margin-bottom: 5px;
  width: 300px;
  border: 1px solid var(--lia-bs-border-color);
  border-radius: var(--lia-bs-border-radius-sm);
  box-shadow: var(--lia-bs-box-shadow);
  background-color: var(--lia-bs-white);
  overflow: hidden;
  z-index: 1000;
  position: absolute;
  bottom: 100%;
}

.lia-tag-button {
  padding: 5px 0 5px 5px;
  margin: -5px 0 -5px -5px;
  border-radius: var(--lia-tag-border-radius);

  &:hover {
    color: var(--lia-tag-hover-color);
  }

  @include default-focus-outline();
}

.lia-add-tag-button {
  font-size: var(--lia-bs-font-size-sm);
  font-weight: var(--lia-bs-font-weight-base);
  letter-spacing: var(--lia-letter-spacing-sm);

  + .lia-tag-wrap {
    margin-top: 10px;
  }
}
